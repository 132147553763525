<template>
    <div class="successUpgraded">
        <div class="close" :class="{'vn-italic': $i18n.locale == 'vi'}" @click="goBack">{{ $t('点 击 关 闭') }}</div>
        <div class="name">{{nftValue.name}}</div>
        <div class="qrinten">QRINTEN | LvL.{{nftValue.nft_grade}}</div>
        <img class="img" :src="nftValue.cover" alt="">
        <div class="obtained">
            <span>OBTAINED</span>
            <div class="get" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('获得')}}</div>
        </div>
        <div class="success" :class="{'vn-italic': $i18n.locale == 'vi'}">{{ $t('交易成功后等级 + 1') }}</div>
        <div class="dayu">LVL {{nftValue.nft_grade}}   >   LVL {{Number(nftValue.nft_grade) + 1}}</div>
        <!-- <div class="total">- {{$t('总支出')}} -</div>
        <div class="solarix">-8,000 Bitney</div> -->

        <div class="btm">
            <div class="left">
                <img src="@/assets/bracelet_img/46.png" alt="">
            </div>
            <div class="right">
                <span class="mar_ri_11">M</span>
                <span class="mar_ri_11">E</span>
                <span class="mar_ri_11">T</span>
                <span class="mar_ri_11">A</span>
                <span class="mar_ri_11">N</span>
                <span class="mar_ri_11">E</span>
                <span class="mar_ri_11">B</span>
                <span class="mar_ri_11">U</span>
                <span class="mar_ri_11">L</span>
                <span class="mar_ri_11">A</span>
                <span>S</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nftValue: {}
        }
    },
    created(){
        this.nftValue = JSON.parse(this.$route.query.data)
    },
    methods: {
        goBack(){
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="less" scoped>
    .successUpgraded{
        width: 100%;
        min-height: 100vh;
        background: #2F2F2F;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 27px;
        padding-bottom: 70px;
        color: #ffffff;
        .close{
            font-family: 'REEJI-PinboGB-Flash';
            font-size: 18px;
            opacity: 0.4;
            // letter-spacing: 9px;
            margin-bottom: 80px;
        }
        .name{
            font-family: 'AeroMaticsBoldItalic';
            font-size: 30px;
            margin-bottom: 1px;
        }
        .qrinten{
            font-family: 'AeroMaticsBold';
            font-size: 18px;
            color: #a7f264;
            margin-bottom: 10px;
        }
        .img{
            width: 134px;
	        height: 134px;
        }
        .obtained{
            position: relative;
            span{
                font-family: 'KenyanCoffeeRg-Bold';
                font-size: 122px;
                opacity: 0.1;
            }
            .get{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                font-family: 'REEJI-PinboGB-Flash';
                font-size: 75px;
                color: transparent;
                width: 100%;
                text-align: center;
                background: -webkit-linear-gradient(left bottom, #AEFFAE, #19FFF4);//#58FFD6
                /*  Safari 5.1 到 6.0 */
                background: -o-linear-gradient(top right, #AEFFAE, #19FFF4);
                /*  Opera 11.6 到 12.0 */
                background: -moz-linear-gradient(top right, #AEFFAE, #19FFF4);
                /*  Fx 3.6 到 15 */
                background: linear-gradient(to top right,#AEFFAE, #19FFF4);
                /* 标准语法（必须是最后一个） */
                background-clip: text;
                -webkit-background-clip: text;
            }
        }
        .success{
            font-family: 'REEJI-PinboGB-Flash';
            font-size: 15px;
            // letter-spacing: 6px;
            color: #a7f264;
            // margin-bottom: 5px;
        }
        .dayu{
            font-family: 'AeroMaticsBoldItalic';
	        font-size: 30px;
            margin-bottom: 100px;
        }
        .total{
            font-family: 'REEJI-PinboGB-Flash';
	        font-size: 15px;
            margin-bottom: 6px;
        }
        .solarix{
            background-color: #ffffff;
            border-radius: 15px;
            padding: 6px 60px;
            font-family: 'AeroMaticsDisplayItalic';
            font-size: 18px;
            color: #2b2b2b;
        }
        .btm{
            // width: 333px;
            // height: 40px;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 0px 25px 5px 0px;
            display: flex;
            align-items: center;
            position: fixed;
            // width: 100%;
            left: 0;
            bottom: 10px;
            padding: 5px 33px 5px 0;
            .left{
                background-color: rgba(43, 43, 43, 0.9);
                border-radius: 0 3px 3px 0;
                border: 2px solid #a7f264;
                border-left: none;
                padding: 6px 15px;
                display: flex;
                margin-right: 14px;
                img{
                    width: 25px;
	                height: 19px;
                }
            }
            .right{
                font-family: 'KenyanCoffeeRg-BoldItalic';
                font-size: 30px;
                color: #ffffff;
                .mar_ri_11{
                    margin-right: 11px;
                }
            }
        }
    }
    @media screen and (min-width: 480px){
        //在这里写非小屏幕设备的样式  -- 非手机
        .successUpgraded{
            .btm{
                position: fixed;
                left: calc(50% - 215px);
                bottom: 10px;
            }
        }
    }
</style>
